import React from "react"
import { Router } from "@reach/router"
import { useIntl, getLanguagePathPrefix } from "../utils"
import { Layout, SEO, Protected } from "../components"
import { Client, Clients } from "../containers"
const Page = () => {
  const intl = useIntl()
  const languagePathPrefix = getLanguagePathPrefix(intl)
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "hello" })} />
      <Router basepath={`${languagePathPrefix}/clients`}>
        <Protected path="/:clientId" component={Client}></Protected>
        <Protected path="/" component={Clients} />
      </Router>
    </Layout>
  )
}
export default Page
